export default defineNuxtRouteMiddleware(async (_to, _from) => {
	// skip middleware on server
	if (import.meta.server) return;
	// skip middleware on client side entirely
	// if (import.meta.client) return;

	// or only skip middleware on initial client load
	// const nuxtApp = useNuxtApp();
	// if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return;

	const authStore = useAuthStore();

	// if user is not authorised, navigate to login page
	// need to be aware that useAuthStore() will not be available in the middleware

	if (!authStore.isAuthorised) {
		return await navigateTo('/login');

		// create a alert dialog
		// const shouldNavigate = window.confirm("You are not authorised. Do you want to login?");
		// if (shouldNavigate) {
		//     return await navigateTo("/login");
		// }
		// return false;
	}
});
